import cx from 'classnames';
import React, {useEffect, useReducer, useState} from 'react';
import { AddReviewForm } from '../components/add-review-form-modal';
import { Breadcrumbs, BreadcrumbsItem } from '../components/breadcrumbs';
import { Container } from '../components/container';
import { Review } from '../components/review';
import {homepageTabsReducer, initialState} from "../contexts/HomepageTabsContext";

const ReviewsPage = () => {
  const [state, dispatch] = useReducer(homepageTabsReducer, initialState)
  useEffect(() => {
    dispatch({ type: "OPEN_TAB2" })
  }, [])

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer &&
  //   window.dataLayer.push({
  //     pageURL: "/reviews",
  //     testovani: websiteVersion,
  //   })
  // }, [websiteVersion])

  return (
    <Container>
      <Breadcrumbs>
        <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
        <BreadcrumbsItem link="/reviews">Recenze</BreadcrumbsItem>
      </Breadcrumbs>

      <h3 className="fancyUnderlineText sectionTitleNew text-center">
        Všechny <span>recenze</span>
      </h3>
      <AddReviewForm />
      {/* <CategoriesFilterPanel /> */}
      <Review />
    </Container>
  )
};

export default ReviewsPage;