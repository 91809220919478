import React from "react"

import { ReviewCard } from "./card"
import styles from "./review.module.css"
import nutrition from "../../../static/images/review-icons/nutrition.svg"
import google from "../../../static/images/review-icons/google.svg"
import fb from "../../../static/images/review-icons/facebook.svg"

const reviews = [
  {
    id: 1,
    name: "Matěj Schultz",
    date: "20.04.2021",
    text:
      "Nejlepší poměr cena/kvalita, neskutečně jste mi pomohli, moc děkuji!!",
  },
  {
    id: 2,
    name: "Klára Onderková",
    date: "20.04.2021",
    text: "Vždy dovoz včas a supr servis, díky za ušetřený čas nutrition pro!!",
  },
  // {
  //   id: 3,
  //   name: "Ludmila Koneva",
  //   date: "20.04.2021",
  //   text:
  //     "Velice oceňuji ochotu servisu a skvělé jídlo, zkoušela jsem Fitkitchen a Zdrave stravovani a musím říct, že nebyla jsem s nimi ani blízko spokojena jako s vámi, děkuji děkuji nenapíšem správně naše jméno?",
  // },
  {
    id: 4,
    name: "Aneta pecková",
    date: "20.04.2021",
    text:
      "možná první podobný servis, který mi během prvního týdnu nezklamal, dokonce naopak, paráda!!",
  },
  {
    id: 5,
    name: "Gregory Pelec",
    date: "20.04.2021",
    text:
      "Its been a while since I started my plan with you and surpisingly every meal was very delicious and the kilos went down finally!! Highly recommend The Home office pack to those who spend everyday in the office as I do",
  },
  {
    id: 6,
    name: "Jitka Beranová",
    date: "20.04.2021",
    text:
      "Blíží se ke konci první měsíc co jsem s Vámi a už se citím tísickrát lépe, minus 3 kila a konečně mám čas a energii, jste naprosto Boží",
  },
  {
    id: 7,
    name: "Petr Hrdina",
    date: "20.04.2021",
    text:
      "Myslel jsem si, ze uz nikdy nezhubnu, ani nematu predstavu, jak jsem vam vdecny",
  },
  {
    id: 8,
    name: "Nicole Chandy",
    date: "20.04.2021",
    text:
      "Wanted to share my thoughts for a while already and its time to do so: the food is almost at a restaurant level, so is the servis. Love at first sight (or at the first taste if you know what I mean)",
  },
  {
    id: 9,
    name: "Monika Horáková",
    date: "20.04.2021",
    text:
      "musim vam poděkovat za mou postavu a ušetřený čas lidé moc doporučuji",
  },
  {
    id: 10,
    name: "Анна Алексеева",
    date: "20.04.2021",
    text:
      "Очень бы хотелось оценить высокое качество сервиса на том конце телефона, молодцы",
  },
  {
    id: 11,
    name: "Jindra Chmátal",
    date: "20.04.2021",
    text:
      "Výnikající ryba, nevím, jak to děláte, ale pro ní radší za váma než do restaurace",
  },
  {
    id: 12,
    name: "zděnek r",
    date: "20.04.2021",
    text:
      "nemohl jsem pribrat ani kilo a dostal jsem na vas doporuceni od kamarada ktery vas objednava, po trech mesicich jsem pribral 4 kila! fakt velky diky vasemu dietologovi",
  },
  {
    id: 13,
    name: "Kateřina Legnerová",
    date: "20.04.2021",
    text: "Zamilovala jsem se do vašich jídel",
  },
  {
    id: 14,
    name: "Terka Říčánová",
    date: "20.04.2021",
    text: "máte to výborné, dokonce se mi občas vás chce zeptat na recepty",
  },
  {
    id: 15,
    name: "Alžběta Doubková",
    date: "20.04.2021",
    text: "Poklona kuchařům",
  },
  {
    id: 16,
    name: "Jan Hamáček",
    date: "20.04.2021",
    text:
      "První dieta opravdu odpovidající popisu, je úplně skvělé jak všechno máte promyšlené do detailů",
  },
  {
    id: 17,
    name: "Maxim Abramov",
    date: "20.04.2021",
    text:
      "After almost six month of ordering you I`m still satisfied. I didn`t even expect such a diversed meal plan gyus",
  },
  {
    id: 18,
    name: "Eva Brouzkova",
    date: "20.04.2021",
    text:
      "Jste asi nejlepsi krabicova dieta kterou jsem kdy zkousela a mela jsem jich fakt hodne, salaty jsou naprosto perfektni",
  },
  {
    id: 19,
    name: "Ondřej Suchánek",
    date: "20.04.2021",
    text:
      "S přitelkyni jsme vás před chvíli objednali a musím říct, že jen tak dal, famozní jídla a kompetentní servis, todle jsem ani nečekal",
  },
  {
    id: 20,
    name: "Vít Mikuláš",
    date: "20.04.2021",
    text:
      "Takovou chuť jsem úpřímně za tu cenu nečekal, kéž bych o vás věděl dříve, byl bych na létuo přípravený",
  },
  {
    id: 21,
    name: "ollie fox",
    date: "20.04.2021",
    text:
      "super jídla a super telefonický servis, konečně někdo dokáže sestavit individuální program bez blbostí",
  },
  {
    id: 22,
    name: "Monika Sedlačková",
    date: "20.04.2021",
    text: "S manželem jsme nikdy nebyli spokojenější, děkujeme za vstříčnost",
  },
  {
    id: 23,
    name: "Jírka",
    date: "20.04.2021",
    text:
      "Mockrát mi pomohl ten váš hundoucí program, po dlouhé době, co se snažím zhubnout konečně něco fungujícího , děkuji",
  },
  {
    id: 24,
    name: "Jan Jan",
    date: "20.04.2021",
    text:
      "Moc se mi líbí že jídla jsou opravdu vyvážené a k tomu dobře chutnájí, nemám co podotknout jste skvělí moc děkuji",
  },
  {
    id: 25,
    name: "Hanka Moravcová",
    date: "20.04.2021",
    text:
      "Vaše jídla mi doporučil tréner za což jsem mu velice vděčná, pomohli jste mi naplnit cíle po dlouhé stagnaci, doporučuji všem!!!",
  },
  {
    id: 26,
    name: "Gena H",
    date: "20.04.2021",
    text:
      "chci vám osobně poděkovat za snídaně a svačiny konečně jsem se vzdál brambůrek a dortů",
  },
  {
    id: 27,
    name: "Pol Plotzky",
    date: "20.04.2021",
    text:
      "Undoubtedly the best diet in Prague meanwhile especially due to the quality of servis and management and of course the quality of the food, huge thanks",
  },
  {
    id: 28,
    name: "honza přibral",
    date: "20.04.2021",
    text: "teď už pokud jídlo s dovozem, tak žádný mekáč, jen vy",
  },
  {
    id: 29,
    name: "Simona Haganová",
    date: "20.04.2021",
    text:
      'Musím ocenit s jakou přesností doručujete jídla, možná první "krabičková dieta", která své sliby opravdu dodržuje, dále jen tak prosím',
  },
  {
    id: 30,
    name: "Jindra Býk",
    date: "20.04.2021",
    text:
      "Nadmíru jste moje očekávaní splnili co se týče chutě, pan po telefonu je náprosty profesional, fakt vám rád volám :)",
  },
  {
    id: 31,
    name: "Арина Клюева",
    date: "20.04.2021",
    text:
      "Супер вкусно, супер полезно, все посчитали сами, обо всем позаботились. Раудет, что хороший сервис еще хоть где-то остался) Про еду вообще молчу)",
  },
  {
    id: 32,
    name: "David David",
    date: "20.04.2021",
    text: "Moje uznání celé firmě a prosím držet tuto kvalitu",
  },
  {
    id: 33,
    name: "draho mírný",
    date: "20.04.2021",
    text:
      "začal jsem posilovat ještě před rokem a měl jsem velký problém ohledně nabírání svalové hmoty, nepomáhaly mě ani protejnaky ani tuny masa teď ale po pár měsících s váma +4 kila svalů nevím co v tom jídlu máte ale moc vám děkuji",
  },
  {
    id: 34,
    name: "Conor Lids",
    date: "20.04.2021",
    text:
      "The most suitable office pack for office workers (me), having your meals on the desk instead of unhealthy snacks is a habit aready, thank you for my energy and time saved NP",
  },
  {
    id: 35,
    name: "Elišká Novotná",
    date: "20.04.2021",
    text:
      "Zase bych Vám chtěla poděkovat za až natolik rozmanitý jídelníček a to samé za manžela",
  },
  {
    id: 36,
    name: "Petr Max",
    date: "20.04.2021",
    text:
      "Nepíšu normálně recenze ale fakt musím říct, že jsem nedostal ještě jídlo, které by mi nechutnalo, trefíte se vždy přesně",
  },
  {
    id: 37,
    name: "Šája Charlotta",
    date: "20.04.2021",
    text:
      "Nadšena jsem zákaznickým přístupem, úplně jiný, řekla bych západní level",
  },
  {
    id: 38,
    name: "Adam Kr",
    date: "20.04.2021",
    text:
      "možná jedna z nejlepších podobných firem, zkoušel jsem jich mnoho a po prvním měsici s vámi už vím, že zůstávám",
  },
  {
    id: 39,
    name: "Kristynka",
    date: "20.04.2021",
    text:
      "dnešní večeře naprosto bomba nelitovali jsme že jsme nešli pro jídlo ven",
  },
  {
    id: 40,
    name: "Tereza Petrova",
    date: "20.04.2021",
    text:
      "Bojím se vás vynachválit aby všechno zůstalo jak to je ale musím, chutnálo mi zatím absolutně každé jídlo, zejména dezerty jsou aaach",
  },
]

export const Review = () => {
  return (
    <div className={styles.review}>
      <ReviewCard
        iconType={nutrition}
        name="Matěj Schultz"
        date="20.04.2021"
        text="Nejlepší poměr cena/kvalita, neskutečně jste mi pomohli, moc děkuji!!"
      />

      <ReviewCard
        iconType={nutrition}
        name="Klára Onderková"
        date="20.04.2021"
        text="Vždy dovoz včas a supr servis, díky za ušetřený čas nutrition pro!!"
      />

      {/* <ReviewCard
        name="Ludmila Koneva"
        date="20.04.2021"
        text="Velice oceňuji ochotu servisu a skvělé jídlo, zkoušela jsem Fitkitchen a Zdrave stravovani a musím říct, že nebyla jsem s nimi ani blízko spokojena jako s vámi, děkuji děkuji nenapíšem správně naše jméno?"
      /> */}

      <ReviewCard
        iconType={nutrition}
        name="Aneta pecková"
        date="20.04.2021"
        text="možná první podobný servis, který mi během prvního týdnu nezklamal, dokonce naopak, paráda!!"
      />

      <ReviewCard
        iconType={nutrition}
        name="Gregory Pelec"
        date="20.04.2021"
        text="Its been a while since I started my plan with you and surpisingly every meal was very delicious and the kilos went down finally!! Highly recommend The Home office pack to those who spend everyday in the office as I do "
      />

      <ReviewCard
        iconType={nutrition}
        name="Jitka Beranová"
        date="20.04.2021"
        text="Blíží se ke konci první měsíc co jsem s Vámi a už se citím tísickrát lépe, minus 3 kila a konečně mám čas a energii, jste naprosto Boží"
      />

      <ReviewCard
        iconType={nutrition}
        name="Petr Hrdina"
        date="20.04.2021"
        text="Myslel jsem si, ze uz nikdy nezhubnu, ani nematu predstavu, jak jsem vam vdecny"
      />

      <ReviewCard
        iconType={nutrition}
        name="Nicole Chandy"
        date="20.04.2021"
        text="Wanted to share my thoughts for a while already and its time to do so: the food is almost at a restaurant level, so is the servis. Love at first sight (or at the first taste if you know what I mean)"
      />

      <ReviewCard
        iconType={nutrition}
        name="Monika Horáková"
        date="20.04.2021"
        text="musim vam poděkovat za mou postavu a ušetřený čas lidé moc doporučuji"
      />

      <ReviewCard
        iconType={nutrition}
        name="Анна Алексеева"
        date="20.04.2021"
        text="Очень бы хотелось оценить высокое качество сервиса на том конце телефона, молодцы"
      />

      <ReviewCard
        iconType={nutrition}
        name="Jindra Chmátal"
        date="20.04.2021"
        text="Výnikající ryba, nevím, jak to děláte, ale pro ní radší za váma než do restaurace"
      />

      <ReviewCard
        iconType={nutrition}
        name="zděnek r"
        date="20.04.2021"
        text="nemohl jsem pribrat ani kilo a dostal jsem na vas doporuceni od kamarada ktery vas objednava, po trech mesicich jsem pribral 4 kila! fakt velky diky vasemu dietologovi"
      />

      <ReviewCard
        iconType={nutrition}
        name="Kateřina Legnerová"
        date="20.04.2021"
        text="Zamilovala jsem se do vašich jídel"
      />

      <ReviewCard
        iconType={nutrition}
        name="Terka Říčánová"
        date="20.04.2021"
        text="máte to výborné, dokonce se mi občas vás chce zeptat na recepty"
      />

      <ReviewCard
        iconType={nutrition}
        name="Alžběta Doubková"
        date="20.04.2021"
        text="Poklona kuchařům"
      />

      <ReviewCard
        iconType={nutrition}
        name="Jan Hamáček"
        date="20.04.2021"
        text="První dieta opravdu odpovidající popisu, je úplně skvělé jak všechno máte promyšlené do detailů"
      />

      <ReviewCard
        iconType={nutrition}
        name="Maxim Abramov"
        date="20.04.2021"
        text="After almost six month of ordering you I`m still satisfied. I didn`t even expect such a diversed meal plan gyus"
      />

      <ReviewCard
        iconType={nutrition}
        name="Eva Brouzkova"
        date="20.04.2021"
        text="Jste asi nejlepsi krabicova dieta kterou jsem kdy zkousela a mela jsem jich fakt hodne, salaty jsou naprosto perfektni"
      />

      <ReviewCard
        iconType={nutrition}
        name="Ondřej Suchánek"
        date="20.04.2021"
        text="S přitelkyni jsme vás před chvíli objednali a musím říct, že jen tak dal, famozní jídla a kompetentní servis, todle jsem ani nečekal"
      />

      <ReviewCard
        iconType={nutrition}
        name="Vít Mikuláš"
        date="20.04.2021"
        text="Takovou chuť jsem úpřímně za tu cenu nečekal, kéž bych o vás věděl dříve, byl bych na létuo přípravený"
      />

      <ReviewCard
        iconType={nutrition}
        name="ollie fox"
        date="20.04.2021"
        text="super jídla a super telefonický servis, konečně někdo dokáže sestavit individuální program bez blbostí"
      />

      <ReviewCard
        iconType={nutrition}
        name="Monika Sedlačková"
        date="20.04.2021"
        text="S manželem jsme nikdy nebyli spokojenější, děkujeme za vstříčnost"
      />

      <ReviewCard
        iconType={nutrition}
        name="Jírka"
        date="20.04.2021"
        text="Mockrát mi pomohl ten váš hundoucí program, po dlouhé době, co se snažím zhubnout konečně něco fungujícího , děkuji"
      />

      <ReviewCard
        iconType={nutrition}
        name="Jan Jan"
        date="20.04.2021"
        text="Moc se mi líbí že jídla jsou opravdu vyvážené a k tomu dobře chutnájí, nemám co podotknout jste skvělí moc děkuji"
      />

      <ReviewCard
        iconType={nutrition}
        name="Hanka Moravcová"
        date="20.04.2021"
        text="Vaše jídla mi doporučil tréner za což jsem mu velice vděčná, pomohli jste mi naplnit cíle po dlouhé stagnaci, doporučuji všem!!!"
      />

      <ReviewCard
        iconType={nutrition}
        name="Gena H"
        date="20.04.2021"
        text="chci vám osobně poděkovat za snídaně a svačiny konečně jsem se vzdál brambůrek a dortů"
      />

      <ReviewCard
        iconType={nutrition}
        name="Pol Plotzky"
        date="20.04.2021"
        text="Undoubtedly the best diet in Prague meanwhile especially due to the quality of servis and management and of course the quality of the food, huge thanks"
      />

      <ReviewCard
        iconType={nutrition}
        name="honza přibral"
        date="20.04.2021"
        text="teď už pokud jídlo s dovozem, tak žádný mekáč, jen vy"
      />

      <ReviewCard
        iconType={nutrition}
        name="Simona Haganová"
        date="20.04.2021"
        text='Musím ocenit s jakou přesností doručujete jídla, možná první "krabičková dieta", která své sliby opravdu dodržuje, dále jen tak prosím'
      />

      <ReviewCard
        iconType={nutrition}
        name="Jindra Býk"
        date="20.04.2021"
        text="Nadmíru jste moje očekávaní splnili co se týče chutě, pan po telefonu je náprosty profesional, fakt vám rád volám :)"
      />

      <ReviewCard
        iconType={nutrition}
        name="Арина Клюева"
        date="20.04.2021"
        text="Супер вкусно, супер полезно, все посчитали сами, обо всем позаботились. Раудет, что хороший сервис еще хоть где-то остался) Про еду вообще молчу)"
      />

      <ReviewCard
        iconType={nutrition}
        name="David David"
        date="20.04.2021"
        text="Moje uznání celé firmě a prosím držet tuto kvalitu"
      />

      <ReviewCard
        iconType={nutrition}
        name="draho mírný"
        date="20.04.2021"
        text="začal jsem posilovat ještě před rokem a měl jsem velký problém ohledně nabírání svalové hmoty, nepomáhaly mě ani protejnaky ani tuny masa teď ale po pár měsících s váma +4 kila svalů nevím co v tom jídlu máte ale moc vám děkuji"
      />

      <ReviewCard
        iconType={nutrition}
        name="Conor Lids"
        date="20.04.2021"
        text="The most suitable office pack for office workers (me), having your meals on the desk instead of unhealthy snacks is a habit aready, thank you for my energy and time saved NP"
      />

      <ReviewCard
        iconType={nutrition}
        name="Elišká Novotná"
        date="20.04.2021"
        text="Zase bych Vám chtěla poděkovat za až natolik rozmanitý jídelníček a to samé za manžela"
      />

      <ReviewCard
        iconType={nutrition}
        name="Petr Max"
        date="20.04.2021"
        text="Nepíšu normálně recenze ale fakt musím říct, že jsem nedostal ještě jídlo, které by mi nechutnalo, trefíte se vždy přesně"
      />

      <ReviewCard
        iconType={nutrition}
        name="Šája Charlotta"
        date="20.04.2021"
        text="Nadšena jsem zákaznickým přístupem, úplně jiný, řekla bych západní level"
      />

      <ReviewCard
        iconType={nutrition}
        name="Adam Kr"
        date="20.04.2021"
        text="možná jedna z nejlepších podobných firem, zkoušel jsem jich mnoho a po prvním měsici s vámi už vím, že zůstávám"
      />

      <ReviewCard
        iconType={nutrition}
        name="Kristynka"
        date="20.04.2021"
        text="dnešní večeře naprosto bomba nelitovali jsme že jsme nešli pro jídlo ven"
      />

      <ReviewCard
        iconType={nutrition}
        name="Tereza Petrova"
        date="20.04.2021"
        text="Bojím se vás vynachválit aby všechno zůstalo jak to je ale musím, chutnálo mi zatím absolutně každé jídlo, zejména dezerty jsou aaach"
      />

      <ReviewCard
        iconType={nutrition}
        name="Barbora Šimková"
        date="10.08.2021"
        text="Děkuji za pomoc se stravou při histaminové intoleranci a při exémech. Pomohlo mi to hlavně psychicky. Doktoři mi jen zakazovali jíst různé potraviny ale paní Dokoupilova mi poradila jak s tím pracivat a opravdu mi to funguje. Děkuji za pomoc!"
      />

      <ReviewCard
        iconType={google}
        name="Adam Pechar"
        date="20.04.2021"
        text="Jedna z nejlepších investic, kterou jsem za poslední dobu učinil, mohu jenom doporučit!
          Bezproblémová spolupráce, vyvážená strava a skvělá chuť. Nejlepší krabičková dieta, kterou jsem doposud vyzkoušel. Po jídle se cítím skvěle a pokaždé se těším na další chod. Zásadní krok pro zdravý životní styl. 👍
          Jedním slovem PERFEKTNÍ"
      />

      <ReviewCard
        iconType={google}
        name="Renata Vydrová"
        date="20.04.2021"
        text="Jídlo mají moc dobré. :) A navíc už nemusím řešit, co budu jaký den vařit. Za mě je to TOP!"
      />

      <ReviewCard
        iconType={google}
        name="Anton Lancevič"
        date="20.04.2021"
        text="Mohu jen doporucit!"
      />

      <ReviewCard
        iconType={google}
        name="Hana Císlerová"
        date="20.04.2021"
        text="Nejlepší krabičky, co jsem kdy zkoušela. Jídla bylo navíc dost, tak jsem se podělila i se synem a vyšlo nám to laciněji, než nakupovat a vařit"
      />

      <ReviewCard
        iconType={google}
        name="Margita Novotná"
        date="20.04.2021"
        text="Porce víc než dostatečné, velmi rozmanité, chutné. Servis perfektní, domluva i další doporučení s ochotnou poradkyní bez chybičky. Doporučuji."
      />

      {/* <ReviewCard
          iconType={google}
          name="Kuba Marlicki (Marlon)"
          date="20.04.2021"
          text="After almost a month I would like to recommend NutritionPro to everyone who want to lose weight. Everything from the very beginning was 100% professional. For now I can say that every meal I got was tasty and what is even more important their diet works. I'm losing weight. Of course apart from diet I also do some exercises and run, but without them It wouldn't be so easy. Special thanks to the delivery guy. He's always nice, always helpful and informing about any possible delays. Best box diet I have ever ordered and your competitors in Prague soon will have a problem If you will keep doing such a good job :)"
        /> */}

      <ReviewCard
        iconType={google}
        name="Kristýna Burdová"
        date="20.04.2021"
        text="Krabičky jsou naprosto super, zachraňovaly mě během home officů, kdy jsem vůbec neměla čas si uvařit, natož něco zdravého. Salesačka Jitka mi pomohla naplánovat optimální kalorický objem tak, abych neměla hlad a zároveň trochu zhubla. Byla jsem moc mile překvapená, že se mě Jitka vyptala na moje stravovací a pohybové návyky a podle toho sestavila krabičky, které jsou velmi chutné. Doporučuju!"
      />

      <ReviewCard
        iconType={google}
        name="Jan Vítek"
        date="20.04.2021"
        text="The fish with the veggies was amazing! 👌"
      />

      <ReviewCard
        iconType={google}
        name="Jan Kříž"
        date="20.04.2021"
        text="Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde.
          Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat cokoli venku.
          Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!"
      />

      <ReviewCard
        iconType={google}
        name="Lev Lyapeikov"
        date="20.04.2021"
        text="Great service! I have ordered twice for the stock. Couriers arrived on time, food quality is excellent. My wife at home with a 6 month old baby and there is no time for cooking. A diet of 2000 calories is enough for my wife all day and I take lunch with me for work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (Pleasantly surprised by the low prices), for almost two weeks I’m free from shopping in groceries. Thank you so much for the yummy food and services!"
      />

      <ReviewCard
        iconType={google}
        name="Nicole Ponce"
        date="20.04.2021"
        text="For someone that is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready everyday, straight to my door. I would highly recommend."
      />

      <ReviewCard
        iconType={google}
        name="Aneta Benova"
        date="20.04.2021"
        text="Velmi chutné a pestré jídlo :)"
      />

      <ReviewCard
        iconType={google}
        name="Antoine Bourg"
        date="20.04.2021"
        text="Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!"
      />

      <ReviewCard
        iconType={google}
        name="Polina Moroz"
        date="20.04.2021"
        text="(Původní) Мне очень понравилось. Меню разнообразное и ,самое главное, вкусное. Советую!

          (Překlad) Opravdu se mi to líbilo. Nabídka je pestrá a hlavně chutná. Doporučuji!"
      />

      <ReviewCard
        iconType={google}
        name="Ondřej Staněk"
        date="20.04.2021"
        text="Výborné krabičky. Moc se neopakují a často člověk ochutná i exotické potraviny, na které by normálně nenarazil. Porce skvělé a vyvážené. Hlad rozhodně nemám a přitom přirozeně pomaličku hubnu"
      />

      <ReviewCard
        iconType={google}
        name="Tereza Žatečková"
        date="20.04.2021"
        text="Krabičky odebírám už delší dobu a stále si drží svou kvalitu. Jídla jsou pestrá, čerstvá a dovážka vždy přesná. Poměr kvalita/cena odpovídá. Doporučuji👍"
      />

      <ReviewCard
        iconType={google}
        name="Viktor Nyitray"
        date="20.04.2021"
        text="S krabičkami jsem hodně spokojený. Nejsou to ty klasické jídla z vývařovny zabalené do krabiček, ale to jídlo je opravdu chutné! Doporučuji alespoň vyzkoušet :)"
      />

      <ReviewCard
        iconType={google}
        name="Irena Rabochova"
        date="20.04.2021"
        text="Jídlo je velmi chutné a vyvážené, z čerstvých surovin. Úspora času a úbytek váhy. Servis je prvotřídní, zejména rozvoz."
      />

      <ReviewCard
        iconType={google}
        name="Lucie Bartošová"
        date="20.04.2021"
        text="Jídlo je naprosto skvělé, ale je tu ještě jedna věc o dost lepší, to jsou dovozci jídla, převážně jedna paní, vždy mi zlepší den, je naprosto dokonalá"
      />

      <ReviewCard
        iconType={google}
        name="Tereza Hofmanová"
        date="20.04.2021"
        text="Velice dobré krabičky, jídla mi vyhovují svou pestrostí. Pro mě jasná doba v období, kdy nestíhám vařit. DOPORUČUJI!"
      />

      <ReviewCard
        iconType={google}
        name="Zdenka Svobodova"
        date="20.04.2021"
        text="Moc chutné jídlo, velmi rozmanité na druhy surovin. A ochutnáte i nevšední jidlo.
          Kombinace mi někdy přišla zvláštní (čočka s rybou), ale vždy opravdu dobře připravené a i přes prvotní zvláštnost to ladí chuťově k sobě.
          Možnost si vybrat ze sladkých či slaných svačin. Mám sladkou ;) a plně uspokojí mlsouny co chtějí i nějaké kilčo shodit.
          Ze začátku jsem vnitřně bojovala s malými porcemi, ale to bylo mou předchozí rozežraností. Za 3 dny jsem si zvykla a po měsíci mohu zhodnotit, že porce jsou akorát, pestré a hlavně mi chutnají. Nemám vlastně pocit, že držím dietu ;) a na jídlo se těším.
          Rozvoz je také super, 3x týdně i v pozdních hodinách a paní co jezdí ke mně, je moc milá
          Děkuji a jen tak dál ;)"
      />

      <ReviewCard
        iconType={google}
        name="Linda Dlabačová"
        date="20.04.2021"
        text="Nový jídelníček ✌️"
      />

      <ReviewCard
        iconType={google}
        name="Maldonado David"
        date="20.04.2021"
        text="Super chutné jídlo"
      />

      <ReviewCard
        iconType={fb}
        name="Jan Kříž"
        date="20.04.2021"
        text="Vždy výborná chuť i vzhled, přesně pro mě na míru, skvělý servis okolo, pro mě dokonalá služba a jsem konečně nakrmený tak, abych nemusel řešit dojídání kdekoli jinde.
          Navíc během covid stavu NutritionPro vyřešilo i situaci lidem kolem mě, kteří můžou zůstat a jíst doma, když se necítí bezpečně shánět a nakupovat venku.
          Takže palec hore a nejen pro letošní rok jasná volba. Díky za ní!!"
      />

      <ReviewCard
        iconType={fb}
        name="Eldar Alizade"
        date="20.04.2021"
        text="Jidlo chutna skvele, netrpim hlady, a hlavne jsem za mesic zhubnul o par kilo! Coz byl hlavni cil. Navic i konzultanti jsou ochotni a komunikace je vyborna. Takze vsechno super, doporucuji!"
      />

      <ReviewCard
        iconType={fb}
        name="Káčko Béčko"
        date="20.04.2021"
        text="Milé překvapení, obrovská spokojenost (můžu srovnávat i s konkurencí). Jídla dle mého gusta, objednala jsem znovu."
      />

      <ReviewCard
        iconType={fb}
        name="Patrick Beznoska"
        date="20.04.2021"
        text="Skvělá služba a jídlo také"
      />

      <ReviewCard
        iconType={fb}
        name="Alan Pock"
        date="20.04.2021"
        text="To je pecka, po měsíci se mi to fakt líbí, asi nejvíce to, že nemusím řešit a pořád vymýšlet co si dám."
      />

      <ReviewCard
        iconType={fb}
        name="Pavel Jiranek"
        date="20.04.2021"
        text="Tasty, quick and neat solution for your daily eating habits!"
      />

      <ReviewCard
        iconType={fb}
        name="Petra Dolanská"
        date="20.04.2021"
        text="Luxusni jidlo, super komunikace a porce akorát. Musím jenom DOPORUČIT"
      />
    </div>
  )
}
